/* ---------------- LATEST CSS CODE -------------------- */
.filter_container {
  display: flex;
  gap: 5px;
}
.filter_block1 {
  padding:10px;
  display: flex;
  gap: 10px;
  width: 77%;
  border: 1px solid #c1c1c1;
}
.filter_block2 {
  padding: 10px;
  display: flex;
  gap: 10px;
  width: 23%;
  border: 1px solid #c1c1c1;
}

.filter_container_block {
  display: flex;
}

.filter_container_block--main {
  display: flex;
}

.filter_container_block--inner {
  display: flex;
}

.filter_field {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
}

.filter_field_date {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 123px;
}

.filter{
  /* border: 1px solid red; */
  margin-top: 4px;
}

.filter_field_btn {
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 10px;
  /* border: 1px solid red; */
  margin-left: 10px;
  padding-bottom: 2px;
}
.icon_filter {
  color: white !important;
}
.filter_btn {
  padding-right: 2rem;
}

.submit_button {
  margin-top: 19px;
}
#demo-mutiple-checkbox {
  width: 110px !important;
}

@media (max-width: 1600px) {
  .filter_field {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
  }

  .filter_field_date {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    max-width: 100px;
  }
  #demo-mutiple-checkbox {
    width: 90px !important;
  }

  .filter_btn {
    padding-right: 1rem;
  }
}
