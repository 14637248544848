.MuiTableSortLabel-root {
  color: grey !important;
}

.table_icon {
  /* padding-top: 10px; */
  display: flex;
  align-items: center;
}

.table_editBtn {
  margin-right: 10px;
  cursor: pointer;
}

.table_logBtn {
  cursor: pointer;
  margin-right: 10px;
}

.priorauth_table_list {
  min-height: 700px !important;
}

.table_cell {
  padding: 0 8px 0 8px !important;
  max-height: 10px !important;
}

.rounding_table_body_cell.MuiTableCell-sizeSmall {
  padding: 0 8px 0 8px !important;
  border: 2px solid red !important;
}

.MuiTableCell-root {
  font-size: 14px !important;
  font-weight: 500 !important;
}
.MuiTablePagination-root {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.MuiTableSortLabel-root {
  color: gray;
}
.MuiSvgIcon-root {
  color: #828282;
}
.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
  padding-left: 152px;
  padding-right: 152px;
}
.MuiTablePagination-root {
  border-radius: 10px;
  border: solid 1px #e0e0e0;
}

.dataTable_head {
  background-color: #f2fbf9;
}

.data_table_list_cell {
  border-color: #d7e0e9 !important;
}

.data_table_list_cell.MuiTableCell-root {
  padding: 0px !important;
}

.data_table_dropdown .MuiInputBase-input {
    padding: 6px 0 3px !important;
}

.hello{
  height: 20px !important;
}

.data_table_dropdown {
  max-width: 120px;
  border-color: #d7e0e9 !important;
  height: 40px;
}
.data_table_dropdown .MuiInputBase-root{
  font-size: 14px !important;
}

.data_table_dropdown.MuiTableCell-root {
  padding: 10px !important;
  font-size: 12px !important;
}

.prior_auth_table_cell.MuiTableCell-sizeSmall {
  padding: 0 8px 0 8px !important;
}

.data_table_dropdown.MuiTableCell-sizeSmall {
  padding: 0 8px 0 8px !important;
}


.table_checkbox {
  display: flex;
  align-items: center;
  /* max-width: 40px;
  padding-left: 12px !important; */
}
.priority_icon {
  color: red;
}

.nodatafound {
  height: 675px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.appointment_add_icon {
  font-size: 4rem !important;
  cursor: pointer;
  position: fixed;
  top: 84%;
  right: 0px;
  left: 92%;
  bottom: 0px;
}
@media (max-width: 1600px) {
  .data_table_list_cell {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-color: #d7e0e9 !important;
  }
}
