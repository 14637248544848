.precedure_details_accordion {
  display: block !important;
}
.procedure_cpt_table {
  padding: 1rem 0;
  padding-bottom: 0;
}
.subheading_procedure_accordion {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
.subheading_procedure_accordion p {
  font-size: 18px;
}

.procedure_button {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
  /* padding: 1.5rem 0 1rem 0; */
}

.procedureSection_header{
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.procedure_info_main_div {
  /* width: 1050px; */
  /* width: 900px; */
  padding: 2rem;
  /* border: 1px solid red; */
  width: 100%;
  border-radius: 8px;
  background-color: white;
}
