.clinic__header {
  display: flex;
  width: 100%;
  top: 0;
  z-index: 10;
  background: #fff;
  height: 80px;
  font-weight: 500 !important;
  border-bottom: 1px solid #d7e0e9;
  position: sticky;
}

@media (max-width: 1520px) {
  .clinic__header {
    justify-content: space-between;
  }
}

.clinic__header__title {
  font-size: 1em;
  padding: 20px 0px;
  font-weight: 500;
}

.clinic__header--inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20rem;
  grid-gap: 10rem;
  width: 100%;
}

.datatable_header {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 95%;
}

.clinic__header--inner_log {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 5%;
}
.column_filter_container{
  padding-left: 20px;
}
.column_filter_container{
  margin-right: 15  px;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */

  padding: 0;
}
.MuiList-padding {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 15px !important;
}
.search_bar{
  padding-bottom: 20px !important;
}
.pagination{
  overflow: hidden;
  padding: 5px;
  white-space: nowrap;
  width: 550px;
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
  padding-left: 0px;
  padding-right: 0px;
}
.backbutton_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5%;
}

.priorauth_header_info {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 650px;
  min-width: 650px;
  white-space: nowrap;
}

.priorauth_header_info ul {
  display: flex;
  list-style-type: none;
  gap: 0.3rem;
}

.priorauth_header_info ul li {
  font-size: 21px;
  cursor: pointer;
  position: relative;
  padding: 10px 8px;
  font-weight: 600;
  color: black;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-left {
  padding-left: 1rem;
}

.header-right {
  padding-right: 1rem;
}

.chat__unreadIndicator {
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  background: #2f80ed;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  font-size: 0.8rem;
  margin-left: 4px;
}

.iconFont {
  font-size: 32px !important;
}

.clinic__header__logoutBtn {
  margin: 0px 10px !important;
}

.clinic__header__logoutBtn>span {
  padding: 8px;
  color: var(--primary-green);
  border-radius: 6px;
  font-size: 0.95em;
  transition: 0.3s ease all;
}

.clinic__header__logoutBtn:hover>span {
  background: var(--primary-green);
  color: #fff;
}

.clinic__profileCircle {
  width: 40px;
  height: 40px;
  background: #e0f0f2;
  color: #139696;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.2s ease all;
}

.clinic__profileCircle:hover {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

/***  Popover styles ***/
.clinicHeader__popover {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.clinicHeader__popover--name {
  font-weight: 600;
  white-space: nowrap;
}

.clinicHeader__popover--list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.4rem;
  color: var(--color-grey-700);
}

.clinicHeader__popover--list div {
  cursor: pointer;
}

.clinicHeader__popover--list .logout {
  color: var(--primary-green--dark);
}

/********/
@media (max-width: 1440px) and (min-width: 900px) {
  .clinicHeader .MuiTypography-h4 {
    font-size: 1.75rem;
  }

  .iconFont {
    font-size: 32px !important;
  }
}



