.pa_history_main {
  padding: 3rem;
  min-height: 700px !important;
}
.nodatafound {
  height: 540px;
}
.pa_history_btns {
  display: flex;
  justify-content: flex-end;
}
.data_table_list_cell{
  /* border: 1px solid red !important; */
  white-space: nowrap !important;
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.data_table_list_cell.MuiTableCell-root {
  padding: 8px !important;
}
@media (max-width: 1600px) {
  .data_table_list_cell {
    max-width: 150px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    border-color: #d7e0e9 !important;
  }
}
.name_info{
  font-size: 1rem;
  margin-top: 5px;
}