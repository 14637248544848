.head_info_container {
  padding: 3rem 4.5rem 0 4.5rem;
}
.mainContainer{
  display: flex;
  width: 100%;
}
.wrapper{
  display: flex;
}
.container_min{
  display: flex;
  flex-direction: column;
  width: 84%;
  padding: 0;

}
.container_max{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}
.leftTabOpen{
  background-color: #c7c7c738;
  width: 18%;
}
.leftTabClosed{
  background-color: #c7c7c738;
  width: 3.5%;
}
.main_edit_section {
  background-color: #c7c7c738;
  padding-top: 0.5rem !important;
  padding-right: 10px;
  width: 50%;
}
.rightTab{
 background-color: #c7c7c738;
  padding-top: 1.5rem;
  padding-left: 10px;
  padding-right: 10px;
  width: 500px;
  width: 50%;
}
.main_edit_section_drawer_open{
 background-color: #c7c7c738;
  padding-top: 0.5rem !important;
  padding-right: 10px;
}
.right_tab_drawer_open{
    background-color: #c7c7c738;
  padding-top: 1.5rem;
  padding-left: 10px;
  padding-right: 10px;
  width: 500px;
  width: 50%;
}
.toggle_button_container{
  border-radius: 100px;
  width: 40px;
  display: flex;
  justify-content: center;
  color: white;
  height: 40px;
  align-items: center;
  background-color: lightgray;
}
.drawer_button{
  width:100px
}
.head_info_container p {
  font-weight: 500;
  font-size: 1.5rem;
}
.patient_info_container {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  width: 1000px;
  /* padding: 20px 70px 0px 70px; */
}
.physician_info_container {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  /* padding: 20px 70px 0px 70px; */
}
.payor_info_container {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  /* padding: 20px 70px 0px 70px; */
}
.procedure_info_container {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  /* width: 900px; */
  /* border: 1px solid red; */
  /* padding: 20px 70px 0px 70px; */
}
.fileUpload_info_container{
   display: flex;
  justify-content: center;
  padding: 1rem 0;
}
.edit_ui_heading {
  display: flex;
  gap: 1rem;
  padding: 10px;
}
.MuiAccordionDetails-root {
  display: block !important;
}

@media (max-width: 1872px) {
  /* .mainContainer{
    display: flex;
  width: 100%;
  
  justify-content: right;
  }
  .container_min{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  border: 2px solid green;

}
.container_max{
  display: flex;
  flex-direction: column;
  width: 100%;
  
  padding: 0;

}

  .leftTabOpen {
    padding-right: 10px;
    width: 5%;
    position: absolute;
    left: 0px;
    z-index: 5; 
  }

  .left_tab_inner_wrap{
    height: 100vh;
  }
  
  .main_edit_section_drawer_open{
  padding-top: 0.5rem !important;
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
}
.right_tab_drawer_open{
  padding-top: 1.5rem;
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
}
.main_edit_section {
  background-color: #c7c7c738;
  padding-top: 0.5rem !important;
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
}
.rightTab{
   background-color: #c7c7c738;
  padding-top: 1.5rem;
  padding-left: 10px;
  padding-right: 10px;
  width: 500px;
  width: 50%;
} */
}

