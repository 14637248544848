.diagnosis_main_div p {
  font-size: 18;
  font-weight: 500;
}

.diagnosis_heading {
  padding-bottom: 1rem;
}

.diagnosis_container{
  /* border: 2px solid green; */

  display: flex;
  align-items: center;
}

.diagnosis_grid {
  display: flex;
  /* justify-content: stretch; */
  /* border: 1px solid red; */
  justify-content: space-between;
  width: 80% !important;
}

.dx_field_set {
  display: flex;
  /* border: 1px solid red; */
  /* width: 300px; */
  /* width: 170px; */
  width: 124%;
  /* display: flex; */
  /* margin-right: -68px; */
}

.dx_addicon {
  cursor: pointer;
  position: relative;
  bottom: -21px;
  right: 38px;
}
.dx_searchicon {
  cursor: pointer;
  position: relative;
  right: 79px;
  bottom: -21px;
}
.dx_closeIcon {
  cursor: pointer;
  position: relative;
  right: 38px;
  bottom: -21px;
}
