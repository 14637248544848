.cpt_table_container {
  border: solid 1px #c6c6c6;
  border-radius: 8px !important;
  border-bottom: none;
  overflow-x: auto;
}

.scrollbarY--custom::-webkit-scrollbar {
  width: 0.4em;
  height: 0.6rem;
  border-radius: 0px 10px 0px 0px;
}

.input_material_field{
  /* border :1px solid red !important;  */
  padding: 2px !important;
  /* width: ; */
}

.scrollbarY--custom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.scrollbarY--custom::-webkit-scrollbar-thumb {
  background-color: rgb(182, 182, 182);
  border-radius: 10px 20px 10px 10px;
}

.Cpt_list_card {
  /* padding: 5px; */
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  cursor: pointer;
}

.cpt_add_icon {
  cursor: pointer;
  position: relative;
  bottom: -9px;
  right: 18px;
}
.MuiTypography-colorTextSecondary {
  color: black !important;
}
.cpt_des_container {
  color: grey;
  font-size: 14px;
  /* padding-bottom: 1rem; */
  margin-top: -30px;
}