.callinfo_button {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
  padding: 1rem 0;
  /* border: 1px solid green; */
}
.callinfo_main_div {
  /* width: 1050px !important; */
  padding: 2rem;
  /* border: 1px solid #c6c6c6; */
  border-radius: 8px;
  background-color: white;
}

.log_header{
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px ;
  padding-left: 4px;
}
